export class dotConnectorHelper {
    boardSizeX;
    boardSizeY;

    constructor(boardSizeX = 10, boardSizeY = 10) {
        this.boardSizeX = boardSizeX;
        this.boardSizeY = boardSizeY;
    }
    isValidConnector(from, to) {
        if (this.coordToInt(from) === this.coordToInt(to)) {
            return false;
        }
        if (from.x !== to.x && from.y !== to.y) {
            return false;
        }

        return true;
    }

    intToCoord(coord) {
        return {
            x: Math.floor(coord / 10),
            y: coord % 10,
        }
    }
    coordToInt(coord) {
        return coord.x * 10 + coord.y;
    }

    coordsToConnectorId(from, to) {
        return this.coordToInt(from) * 100 + this.coordToInt(to);
    }

    connectorIdToCoords(connectorId) {
        let from = Math.floor(connectorId / 100);
        let to = connectorId % 100;
        return {
            from: this.intToCoord(from),
            to: this.intToCoord(to),
        };
    }

    isConnectionActive(G, from, to) {
        return G.dotConnections[this.coordsToConnectorId(from, to)] === true;
    }

    setConnectionActive(G, from, to, playerID) {
        G.dotConnections[this.coordsToConnectorId(from, to)] = true;
        let adjacentSquares = [
            {x: from.x - 1, y: from.y - 1}, // TL
            {x: from.x - 0, y: from.y - 1}, // TR
            {x: from.x - 0, y: from.y - 0}, // BR
            {x: from.x - 1, y: from.y - 0}, // BL
        ];
        adjacentSquares.forEach((coord) => {
            if (this.isValidSquareCoord(coord)) {
                if (!this.getSquareOwner(G, coord)) {
                    if (this.isSquareEnclosed(G, coord)) {
                        this.setSquareOwner(G, coord, playerID);
                    }
                }
            }
        })
    }

    isValidSquareCoord(coord) {
        return (coord.x >= 1 && coord.y >= 1 && coord.x <= this.boardSizeX && coord.y <= this.boardSizeY)
    }

    isSquareEnclosed(G, coord) {
        let x = coord.x;
        let y = coord.y;
        return (
            // TOP
            this.isConnectionActive(G, {x: x+0, y: y+0}, {x: x+1, y: y+0})
            &&
            // RIGHT
            this.isConnectionActive(G, {x: x+1, y: y+0}, {x: x+1, y: y+1})
            &&
            // BOTTOM
            this.isConnectionActive(G, {x: x+0, y: y+1}, {x: x+1, y: y+1})
            &&
            // LEFT
            this.isConnectionActive(G, {x: x+0, y: y+0}, {x: x+0, y: y+1})
        );
    }

    squareCoordToInt(coord) {
        return coord.x * 10 + coord.y;
    }

    setSquareOwner(G, coord, playerID) {
        G.squareOwners[this.squareCoordToInt(coord)] = playerID;
    }

    getSquareOwner(G, coord) {
        return G.squareOwners[this.squareCoordToInt(coord)];
    }

     countOwnedSquares(G, playerID) {
        let count = 0;
        Object.values(G.squareOwners).forEach(ownerID => {
            if (parseInt(ownerID) === parseInt(playerID)) {
                count++;
            }
        });
        return count;
    }
}
