import { INVALID_MOVE } from 'boardgame.io/core';
import {dotConnectorHelper} from "./helper/dotConnectorHelper";

export const DotConnector = {
    name: 'DotConnector',
    setup: ({ ctx, ...plugins }, setupData) => ({
        dotConnections: {},
        squareOwners: {},
        boardSizeX: 5,
        boardSizeY: 5,
    }),
    turn: {
        minMoves: 1,
    },
    moves: {
        connectDots: ({ G, ctx, playerID, events }, fromID, toID) => {
            let helper = new dotConnectorHelper(G.boardSizeX, G.boardSizeY);
            if (fromID > toID) {
                let tmp = fromID;
                fromID = toID;
                toID = tmp;
            }
            let from = helper.intToCoord(fromID);
            let to = helper.intToCoord(toID);

            // Validate dotConnection
            if (!helper.isValidConnector(from, to) || helper.isConnectionActive(G, from, to)) {
                return INVALID_MOVE;
            }

            // update dotConnections
            let oldOwnedSquares = helper.countOwnedSquares(G, playerID);
            helper.setConnectionActive(G, from, to, playerID);
            let newOwnedSquares = helper.countOwnedSquares(G, playerID);
            if (newOwnedSquares > oldOwnedSquares) {
                events.endTurn({next: playerID});
            } else {
                events.endTurn();
            }
        },
    },
};

