import {Client} from 'boardgame.io/client';
import {DotConnector} from '../DotConnectorGame';
import {dotConnectorHelper} from '../helper/dotConnectorHelper';

export class DotConnectorClient {
    constructor(rootElement) {
        this.boardSizeX = 5;
        this.boardSizeY = 5;
        this.helper = new dotConnectorHelper(this.boardSizeX, this.boardSizeY);
        this.players = {
            0: "1st Player",
            1: "2nd Player",
        };
        this.client = Client({
            game: DotConnector,
            debug: process.env.NODE_ENV === 'development'
        });
        this.client.start();
        this.rootElement = rootElement;
        this.createBoard();
        this.attachListeners();
        this.client.subscribe(state => this.update(state));
    }
    createBoard() {
        // Create cells in rows for the Tic-Tac-Toe board.
        const rows = [];
        let cells = [];
        let
            fullWidthVmin = 80,
            nodeRadiusVmin = 4,
            nodeDiameterVmin = nodeRadiusVmin * 2,
            connectorWidthVmin = 2,
            connectorMarginVmin = (nodeDiameterVmin - connectorWidthVmin) / 2,
            connectorLengthVmin = (fullWidthVmin - nodeDiameterVmin * this.boardSizeX) / (this.boardSizeX - 1)
        ;
        for (let yIdx = 1; yIdx <= this.boardSizeY; yIdx++) {
            cells = [];
            for (let xIdx = 1; xIdx <= this.boardSizeX; xIdx++) {
                const nodeId = xIdx * 10 + yIdx;
                cells.push(`
                        <div 
                            class="node"
                            style="
                                height: ${nodeDiameterVmin}vmin; 
                                width: ${nodeDiameterVmin}vmin;
                            "
                        >
                            <div 
                                class="content"
                                data-id="${nodeId}"
                                style="
                                    width: ${nodeDiameterVmin}vmin;
                                    border: ${nodeRadiusVmin}vmin black solid;
                                    border-radius: ${nodeRadiusVmin}vmin;
                                "
                            >
                            </div>
                        </div>
                `);
                if (xIdx < this.boardSizeX) {
                    const connectorId = xIdx * 1000 + yIdx * 100 + (xIdx+1) * 10 + yIdx;
                    cells.push(`
                        <div 
                            class="connector connectorX"
                            id="${connectorId}" 
                            data-id="${connectorId}"
                            style="
                                margin-top: ${connectorMarginVmin}vmin;
                                margin-bottom: ${connectorMarginVmin}vmin;
                                width: ${connectorLengthVmin}vmin;
                                height: ${connectorWidthVmin}vmin;
                            "
                        >
                        </div>
                    `);
                }
            }
            rows.push(`<div class="clear">${cells.join('')}</div>`);
            cells = [];
            for (let xIdx = 1; xIdx <= this.boardSizeX; xIdx++) {
                const connectorId = xIdx * 1000 + yIdx * 100 + xIdx * 10 + (yIdx+1);
                cells.push(`
                        <div
                            class="connector connectorY" 
                            id="${connectorId}" 
                            data-id="${connectorId}"
                            style="
                                margin-left: ${connectorMarginVmin}vmin;
                                margin-right: ${connectorMarginVmin}vmin;
                                height: ${connectorLengthVmin}vmin;
                                width: ${connectorWidthVmin}vmin;
                            "
                        >
                        </div>
                `);
                if (xIdx < this.boardSizeX) {
                    const squareId = xIdx * 10 + yIdx;
                    cells.push(`
                        <div 
                            class="square"
                            data-id="${squareId}"
                            style="
                                height: ${connectorLengthVmin}vmin;
                                width: ${connectorLengthVmin}vmin;
                            "
                        >
                            <div 
                                class="owner"
                            >
                            </div>
                        </div>
                    `);
                }
            }
            if (yIdx < this.boardSizeY) {
                rows.push(`<div class="clear">${cells.join('')}</div>`);
            }
        }
        // Add the HTML to our app <div>.
        // We’ll use the empty <p> to display the game winner later.
        const playerIndicators = [];
        for (let playerNumber = 0; playerNumber <= 1; playerNumber++) {
            playerIndicators.push(`
                <div 
                    class="playerBox" 
                    id="playerBox${playerNumber}"
                >
                    <div class="playerName">${this.players[playerNumber]}</div>
                    <div class="currentScore">0</div> 
                </div>
            `);
        }

        this.rootElement.innerHTML = `
            <div 
                id="container-wrapper" 
                style="width: ${fullWidthVmin}vmin; height: ${fullWidthVmin}vmin;"
            >
                <div class="container">
                    ${rows.join('')}
                </div>
            </div>
            <div id="sidebar">
                <div id="playerList">
                    ${playerIndicators.join('')}
                </div>
                <p class="winner"></p>
            </div>
        `;
    }
    attachListeners() {
        // This event handler will read the cell id from a cell’s
        // `data-id` attribute and make the `clickCell` move.
        const handleNodeClick = event => {
            let clickedID = parseInt(event.target.dataset.id);
            let activeElement = this.rootElement.querySelectorAll('.activeNode');
            if (activeElement.length) {
                let activeID = parseInt(activeElement[0].dataset.id);
                this.client.moves.connectDots(activeID, clickedID);
                activeElement.forEach(value => {
                    value.classList.remove('activeNode');
                });
            } else {
                event.target.classList.add('activeNode');
            }
        };
        // Attach the event listener to each of the board nodes.
        const nodes = this.rootElement.querySelectorAll('.node');
        nodes.forEach(node => {
            node.onclick = handleNodeClick;
        });
        const handleConnectorClick = event => {
            if (!event.target.classList.contains('activeConnector')){
                const clickedID = parseInt(event.target.dataset.id);
                const coords = this.helper.connectorIdToCoords(clickedID);
                const from = this.helper.coordToInt(coords.from);
                const to = this.helper.coordToInt(coords.to);
                this.client.moves.connectDots(from, to);
                this.rootElement.querySelectorAll('.activeNode').forEach(node => {
                    node.classList.remove('activeNode');
                })
            }
        };
        // Attach the event listener to each of the board nodes.
        const connectors = this.rootElement.querySelectorAll('.connector');
        connectors.forEach(node => {
            node.onclick = handleConnectorClick;
        });
    }
    setPlayerName(playerNumber, name) {
        this.players[playerNumber] = name;
        let playerNameElement = this.getPlayerBox(playerNumber).querySelector('.playerName');
        playerNameElement.innerText = this.players[playerNumber];
    }
    update(state) {
        // Get all the board connectors.
        const connectors = this.rootElement.querySelectorAll('.connector');
        // Update connectors to display the values in game state.
        connectors.forEach(connector => {
            const cellId = parseInt(connector.dataset.id);
            const cellActive = state.G.dotConnections[cellId];
            if (cellActive) {
                connector.classList.add('activeConnector');
            } else {
                connector.classList.remove('activeConnector');
            }
        });

        // Get all the board squares.
        const squares = this.rootElement.querySelectorAll('.square');
        // Update connectors to display the values in game state.
        squares.forEach(square => {
            const squareId = parseInt(square.dataset.id);
            const squareOwner = state.G.squareOwners[squareId];
            if (squareOwner) {
                square.classList.add('activeSquare' + squareOwner);
                square.querySelector('.owner').innerText = this.players[squareOwner].substring(0,1).toUpperCase();
            }
        });

        // const playerIndicator = document.getElementById('currentPlayer');
        // playerIndicator.innerText = this.players[state.ctx.currentPlayer];

        for (let playerNumber = 0; playerNumber <= 1; playerNumber++) {
            let playerBox = this.getPlayerBox(playerNumber);
            let scoreIndicator = playerBox.querySelector('.currentScore');
            scoreIndicator.innerText = "" + this.helper.countOwnedSquares(state.G, playerNumber);
            if (playerNumber === parseInt(state.ctx.currentPlayer)) {
                playerBox.classList.add('active');
            } else {
                playerBox.classList.remove('active');
            }
        }

        const messageEl = this.rootElement.querySelector('.winner');
        // Update the element to show a winner if any.
        if (state.ctx.gameover) {
            messageEl.textContent =
                state.ctx.gameover.winner !== undefined
                    ? 'Winner: ' + state.ctx.gameover.winner
                    : 'Draw!';
        } else {
            messageEl.textContent = '';
        }
    }
    getPlayerBox(playerNumber) {
        return document.getElementById('playerBox' + playerNumber);
    }
}
